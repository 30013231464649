/* override bootstrap variables */
$blue:    #00a79d !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #f36a24 !default;
$yellow:  #f36a24 !default;
$info:    #f36a24 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;