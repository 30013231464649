@font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-Bold.ttf')
      format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-BoldItalic.ttf')
      format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-Medium.ttf')
      format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-MediumItalic.ttf')
      format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-SemiBold.ttf')
      format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Regular';
    src: url('https://s3.amazonaws.com/dev-centivo-provider-employer-resources/fonts/Regular-SemiBoldItalic.ttf')
      format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  /* import custom variables and mixin */
  @import "custom_bootstrap";
  @import "variables";
  /* Import bootstrap frame css file */
  @import "bootstrap-scss/bootstrap";
  
.navigation-container {
    margin-top: -16px;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: 20px;
}

.flex-align-right {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* custom tab */
.tab-container {
    &.with-notification {
      position: relative;
      height: calc(100vh - 284px);
    }
  
    &.messageList {
      height: calc(100vh - 100px);
    }
  
    .nav-tabs {
      border-bottom: 0;
      .nav-item {
        margin-right: size(2);
      }
      .nav-link {
        font-weight: bold;
        cursor: pointer;
        border-color: $input-border;
        border-bottom-color: transparent;
        color: black;
        &:hover {
          border-color: $blue;
        }
        &.active {
          background: $blue;
          border-color: $blue;
          color: $white;
          &:hover {
            color: $white;
          }
        }
        &.disabled {
          background: $tab-disabled-bg-color;
          &:hover {
            border-color: transparent;
            cursor: initial;
            color: $tab-disabled-text-color;
          }
        }
      }
    }
    .tab-content {
      //height: calc(100vh - 40px);
      border: solid size(1) $border-color;
      @include border-radius(0 4px 4px 4px);
      min-height: 50vh;
      overflow: auto;
      .table---table-wrapper---1t2D3 {
        border: none;
      }
  
      .table---tbody---3q9RK {
        margin-right: size(15);
      }
      .table-header-style {
        @extend %font-regular;
        @extend %uppercase;
        font-size: $size-sm;
        color: $color-gray;
        letter-spacing: size(0.6);
        padding-left: size(12) !important;
        &:first-child {
          padding-left: size(30) !important;
        }
      }
      .pagination-details {
        border-bottom: 0;
      }
      .table-row {
        @extend %font-regular;
        color: $color-text;
        font-size: $size-lg;
        margin-top: size(10);
        min-width: 100% !important;
        .table---tr-expand---sEKPQ {
          border-top: none !important;
        }
        .email-wrapper.inbox {
          position: relative;
        }
        .table---td---EVNU5 {
          height: auto !important;
          padding-left: size(0);
          padding-right: size(0);
          &:first-child {
            padding-left: size(15);
          }
        }
      }
      .table-row.activated {
        border: size(1) solid #95989a;
      }
    }
  
    .tab-page-container {
      margin: size(0);
    }
  
    .dependent {
      tr {
        cursor: pointer;
      }
    }
  }