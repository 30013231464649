@import 'mixin';

$mainbg-color: #f0f0f0;
$brand-color: #00a79d;
$white: #ffffff;
$link-color: #f36a24;
$line-color-dark: #f36a23;
$second-brand-color: #f1416f;
$input-border: #ced4da;
$border-color: #dadada;
$black: #000000;
$table-border: #dee2e6;
$tab-disabled-bg-color: #bbb;
$tab-disabled-text-color: #6c757d;
$table-row-hover-color: #ddd;
$color-dark-blue: #1f124f;
$color-gray: #444444;
$color-light-gray: #95989a;
$color-primary-pink: #ec008c;
$color-red: #d62020;
$color-grey-border: #f5f5f5;
$color-grey-lighter: #f6f7fa;
$color-outline: #bbbdbf;
$color-text: #333333;
$color-gray-secondary: #6d757d;
$color-blue: #5586ef;

%regular_semibold {
  font-family: 'Regular', sans-serif;
  font-weight: 600;
}

%regular_bold {
  font-family: 'Regular', sans-serif;
  font-weight: 700;
}

%regular_bolditalic {
  font-family: 'Regular', sans-serif;
  font-weight: 700;
  font-style: italic;
}

%font-regular {
  font-family: 'Regular', sans-serif;
}

%font-regular-italic {
  font-family: 'Regular', sans-serif;
  font-style: italic;
}

%uppercase {
  text-transform: uppercase;
}

%ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

$size-xl: size(26);
$size-lg: size(16);
$size-md: size(14);
$size-sm: size(12);
$size-xs: size(10);

$mobile: 'only screen and (max-width : 767px)';
$tablet: 'only screen and (min-width : 768px) and (max-width : 1023px)';
$desktop: 'only screen and (min-width : 1024px)';

